export const CONST = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  CLOUDFRONT_URL: process.env.REACT_APP_CLOUDFRONT_URL || '',
  GOOGLE_MAPS_APIKEY: process.env.REACT_APP_GOOGLE_MAPS_APIKEY || '',
  PUBLIC_RELEASE_DATE: process.env.REACT_APP_PUBLIC_RELEASE_DATE || '11/17/2020, 9:00:00 AM',

  MAX_CART_SIZE: 100,
  MAX_CART_WEIGHT: 4400,
  MAX_CART_COUNT: 10,
  DEFAULT_ITEM_WEIGHT: 400,
  DEFAULT_ITEM_SIZE: 20,

  TODAYS_LUNCH: {
    CATEGORY_ID: '8000014',
    DEADLINE_HOUR: 11,
    DEADLINE_MINUTE: 0,
  },

  TIME_LIMITATION_CHECK_INTERVAL: 10 * 1000, // ms
  TIME_STOCK_REFRESH_INTERVAL: 180 * 1000, // ms
  TIME_APP_MSG_REFRESH_INTERVAL: 180 * 1000, // ms
  TIME_DELIVERY_TIME_REFRESH_INTERVAL: 180 * 1000, // ms

  NEXT_DELIVERY_SLOT_INTERVAL_MINUTES: 10,
  PICKUP_DEADLINE_MINUTES: 30,

  // https://snazzymaps.com/style/127403/no-label-bright-colors
  // SubtleGrayscale
  GOOGLE_MAPS_STYLES: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          saturation: '32',
        },
        {
          lightness: '-3',
        },
        {
          visibility: 'on',
        },
        {
          weight: '1.18',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          saturation: '-70',
        },
        {
          lightness: '14',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          saturation: '100',
        },
        {
          lightness: '-14',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
        {
          lightness: '12',
        },
      ],
    },
  ],
};
