import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// MUI
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import Badge from '@mui/material/Badge';
// import Fade from '@mui/material/Fade';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';

import Carousel from 'react-material-ui-carousel';

import { dataShopItem } from '@common/data/ShopItem';
import { useWindowDimensions } from '@common/hooks/UseWindowDimensions';

import coverImage01 from '@common/assets/cover_01.png';
import { ShopItemMasterStock, dataShopItemMaster } from '@common/data/ShopItemMaster';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { dataAppMessages } from '@common/data/AppMessages';
import { userActions } from '@common/data/User';

import { dataOrder } from '@common/data/Order';
import { ShopSearchForm } from './ShopSearchForm';
import { dataShop } from '@common/data/Shop';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { useJudgeSize } from '@common/utils/JudgeSize';
const coverImages = [coverImage01];

export const Shop = () => {
  const { windowHeight, windowWidth } = useWindowDimensions();
  // cover画像サイズ 900x410
  const carouselImageHeight =
    windowHeight > windowWidth && windowWidth <= 1080
      ? (windowWidth * 410) / 900
      : windowHeight / 3;
  const carouselBoxHeight = carouselImageHeight + 40;
  const firstDeliveryTimeOption = dataOrder.deliveryTime.useDeliveryFirstAvailableTimeOption();
  const isOperatingDay = dataOrder.deliveryTime.useIsOperatingDay();
  const selectedSpot = dataDeliveryTo.useSpotSelected();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();
  const refreshAppMessages = dataAppMessages.useAppMessagesRefresher();

  const { spotId } = useParams();
  const [, setSpot] = dataDeliveryTo.useSpotSelectedIdState();
  const isSp = useJudgeSize();
  const navigate = useNavigate();

  // レンダリング前に動作させたいので、layoutEffectを使用
  useLayoutEffect(() => {
    if (spotId) {
      setSpot(spotId);
      refreshItems();
      refreshAppMessages();
      navigate('/');
    }
  });

  // 在庫,メッセージ、配送枠を特定時間ごとに更新
  dataShopItemStock.useShopItemStockRefreshInterval();
  dataAppMessages.useAppMessagesRefreshInterval();
  dataOrder.deliveryTime.useDeliveryTimeOptionRefreshInterval();
  dataOrder.deliveryTo.useUpdateSpotSelectedIdInterval();

  const topMessage = dataAppMessages.useAppMessage('USERAPP_TOP');

  const messageLines = topMessage
    ? topMessage.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))
    : [];

  // eslint-disable-next-line complexity
  const FastestDeliveryTime = () => {
    if (topMessage.trim() === '' && !isOperatingDay) {
      return <div></div>;
    }
    // 配達可能日が全く無い場合、または当日に配送枠がない場合
    if (
      !firstDeliveryTimeOption ||
      moment(firstDeliveryTimeOption.date).diff(moment().startOf('day'), 'days') !== 0
    ) {
      if (messageLines.length === 0) {
        return <div></div>;
      } else {
        return (
          <div
            className={`border border-black rounded-md mt-4 mx-auto p-1 flex items-center gap-2${
              isSp ? 'w-11/12' : 'w-1/2'
            } `}
          >
            <div className="font-bold">
              {messageLines && messageLines.length > 0 && (
                <div className="mb-2">{messageLines}</div>
              )}
            </div>
          </div>
        );
      }
    }
    const fastestDeliveryDay = moment(firstDeliveryTimeOption.date);
    const today = moment().startOf('day');
    const daysDiff = fastestDeliveryDay.diff(today, 'days');

    let dayStr;
    switch (daysDiff) {
      case 0:
        dayStr = '本日';
        break;
      case 1:
        dayStr = '明日';
        break;
      case 2:
        dayStr = '明後日';
        break;
      default:
        dayStr = fastestDeliveryDay.format('M月D日');
        break;
    }

    return (
      <div
        className={`border border-black rounded-md mt-4 mx-auto p-1
      ${isSp ? 'w-11/12' : 'w-1/2'} `}
      >
        <div className="font-bold">
          {messageLines && messageLines.length > 0 && <div className="mb-2">{messageLines}</div>}
          <div>
            今すぐご注文で {dayStr} {firstDeliveryTimeOption.toStringTime('〜')}に
            <br />
            {selectedSpot.spotName}の受取ボックスまでお届けします。
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <React.Suspense
        fallback={
          // +95は画面を見ながら適当に設定した値、デザインが変わると変更が必要
          <Box sx={{ height: carouselBoxHeight + 95 }}>
            <Skeleton
              variant="rectangular"
              height={240}
              sx={{ marginX: 'auto' }}
              animation="wave"
            />
          </Box>
        }
      >
        <FastestDeliveryTime />
        <ShopSearchForm />
        {/* <Fade in timeout={2000}> ★フェードさせたいが効かない*/}
        <Box sx={{ height: carouselBoxHeight }}>
          <OfferItemsCarousel height={carouselImageHeight} />
        </Box>
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Categories />
      </React.Suspense>
      {/* <Box className="p-4">
        <Box className="flex items-end">
          <MenuBookRoundedIcon className="mr-2" />
          その他のカテゴリー
        </Box>
        <Stack>
          {categories.map((category) =>
            <Card key={category.name} className="object-fill mx-4 my-2">
              <CardActionArea onClick={() => navigate("/shop/categories/" + category.name)}>
                <CardContent>
                  <div className="">
                    {category.name}
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </Stack>
      </Box> */}
    </>
  );
};

const Categories = () => {
  const navigate = useNavigate();
  const categories = dataShopItem.category.useCategories();
  const isSp = useJudgeSize();

  return (
    <div className={`${isSp ? '' : 'px-12'} ml-4`}>
      {categories.map((category) => (
        <div key={category.categoryId} className="mb-4">
          <div className="flex">
            <div className="grow">
              <span className="mr-2">
                {category.categoryName} <MenuBookRoundedIcon className="pb-1" />
              </span>
            </div>
            <div className="mr-1 cursor-pointer">
              <span onClick={() => navigate('/shop/categories/' + category.categoryId)}>
                もっと見る <DoubleArrowRoundedIcon fontSize="small" className="pb-1" />
              </span>
            </div>
          </div>
          <Suspense
            fallback={
              <Skeleton
                variant="rectangular"
                width={isSp ? '29vw' : '17vw'}
                height={isSp ? '192px' : '168px'}
                animation="wave"
                border-radius="10px"
              />
            }
          >
            <CategoryItems categoryId={category.categoryId} />
          </Suspense>
        </div>
      ))}
    </div>
  );
};

const CategoryItems = ({ categoryId }: { categoryId: string }) => {
  const navigate = useNavigate();
  const items = dataShopItem.category.useCategoryItemsInLimit(categoryId);
  const [itemMaster] = dataShopItemMaster.useShopItemsMasterState();
  const selectedShop = dataShop.useShopSelected();
  const [stockMaster] = dataShopItemStock.useShopItemsStockAllState(selectedShop.shopId);
  const updateItemMaster = dataShopItemMaster.useShopItemMasterUpdate();
  const updateItemStock = dataShopItemStock.useShopItemStockUpdate();
  const cognitoUserInfo = userActions.useCognitoUserInfo();
  const isSp = useJudgeSize();
  const [filteredItems, setFilteredItems] = useState<ShopItemMasterStock[]>([]);

  useEffect(() => {
    if (isSp) {
      // 特定の幅以下は3つ表示
      setFilteredItems(items.slice(0, 3));
      return;
    }
    setFilteredItems(items);
  }, [isSp, items]);

  return (
    <div className="mt-2 pb-1 flex w-full">
      {filteredItems.map((item) => (
        <div
          key={item.itemId}
          className="shrink-0 flex flex-col mr-2 items-center rounded-md shadow cursor-pointer"
          style={{ width: isSp ? '29vw' : '17vw' }}
          onClick={() => {
            updateItemMaster(item.itemId, itemMaster);
            updateItemStock(item.itemId, stockMaster);
            navigate('/shop/items/' + item.itemId);
          }}
        >
          <div className="w-full h-24 relative">
            <img
              alt="商品画像"
              src={item.image[0]}
              className="w-full h-full object-contain rounded-t-md"
              loading="lazy"
            />
            {item.stockCount <= 0 && (
              <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-black/75">
                <div className="font-bold">売り切れ</div>
              </div>
            )}
            {item.stockCount > 0 && item.stockCount <= (item.limitedStock ?? 1) && (
              <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-warning/50">
                <div className="font-bold">残り僅か</div>
              </div>
            )}
          </div>
          <div className="p-2 w-full grow flex flex-col">
            <div className="text-xs font-bold text-primary-light line-clamp-1">
              {item.makerName}
            </div>
            <div className="grow text-base line-clamp-2 lg:line-clamp-1">{item.name}</div>
            {cognitoUserInfo.groups.includes('Developer') && (
              <Badge badgeContent={item.stockCount} color="secondary" max={100000}></Badge>
            )}
            <div className="mt-1 text-right">
              <span className="text-md font-bold">{item.price.toLocaleString()}</span>
              <span className="text-xs"> 円（税込）</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const OfferItemsCarousel = ({ height }: { height: number }) => {
  const navigate = useNavigate();
  const offerItems = dataShopItem.offer.useOfferItems();

  // map を単独利用以外では map 毎に <></> で囲まれたようにまとめられてしまうため
  const carouselItems: Array<JSX.Element> = [];
  coverImages.forEach((item) => {
    carouselItems.push(
      <Box key={item} sx={{ height: height }}>
        <img alt="cover" src={item} className="object-contain h-full w-full" loading="lazy" />
      </Box>
    );
  });
  offerItems.forEach((item) => {
    carouselItems.push(
      <Box
        key={item.itemId}
        sx={{ height: height }}
        className="relative cursor-pointer"
        onClick={() => navigate('/shop/items/' + item.itemId)}
      >
        <img alt="" src={item.image[0]} className="object-cover h-full w-full" loading="lazy" />
        <div
          className="absolute bottom-0 left-0 right-0 px-2
          text-white bg-black/50"
        >
          <div className="font-bold">本日のおすすめ</div>
          {item.name}
        </div>
      </Box>
    );
  });

  return (
    <Carousel
      height={height}
      interval={4000}
      duration={1000}
      animation="fade"
      navButtonsAlwaysVisible={true}
      navButtonsProps={{
        style: {
          color: 'rgb(255,255,255,0.8)',
          background: 'rgb(0,0,0,0.3)',
        },
      }}
      indicatorIconButtonProps={{
        style: {
          padding: '12px',
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: '0',
        },
      }}
    >
      {carouselItems}
    </Carousel>
  );
};
